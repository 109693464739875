<template>
	<div class="test">
		<div class="test-1">
			<el-button type="primary" v-for="(index) in data.length" :key='index' @click='save(index)' round>画师{{index}}</el-button>
		</div>
		<div class="test-2">
			<el-input
			  placeholder="画师"
			  v-model="pack.illustrator"
			  clearable>
			</el-input>
			<el-input
			  placeholder="链接"
			  v-model="pack.authorLink"
			  clearable>
			</el-input>
			<el-input
			  placeholder="封面1"
			  v-model="cover1"
			  clearable>
			</el-input>
			<el-input
			  placeholder="封面2"
			  v-model="cover2"
			  clearable>
			</el-input>
			<el-button type="success" v-for="(index) in data1.length" :key='index' @click='saveArt(index)' round>画集{{index+'::'+data1[index-1][4]}}</el-button>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			data: [[0,"2020-03-14","https://www.pixiv.net/users/490219","Hiten","https://pan.baidu.com/s/1JHRg7f7jwFiJL6FQhp32SQ","a74i","pixiv","74502138"],[1,"2020-03-30","https://www.pixiv.net/users/1655331","Aちき","https://pan.baidu.com/s/15q-xYKE24EbRc-TJKiGkaQ","l29v","pixiv","76026830"],[2,"2020-03-14","https://www.pixiv.net/users/867590","米白粕","https://pan.baidu.com/s/1eNw7ExQ-wNT5AG314eNwFg","jk1l","pixiv","79620819"]
	,[3,"2020-06-06","https://www.pixiv.net/users/2642047","アシマ","https://pan.baidu.com/s/1sdvv-QLrc1EJvA7CHWknhg","u2sb","pixiv","69461006"],[4,"2020-03-31","https://www.pixiv.net/users/3036679","甘城なつき","https://pan.baidu.com/s/1U2PbIUPpmeTNIqvWds_Umg","6xz5","pixiv","78888804"],[5,"2019-11-21","https://www.pixiv.net/users/488766","みふる","https://pan.baidu.com/s/1t3sVBf8Yr1X2ecehFX6whQ","sxyo","pixiv","77559996"]
	,[6,"2020-02-14","https://www.pixiv.net/users/163536","Rella","https://pan.baidu.com/s/17rMOtKFVECjUTGQpHSr8Xg","uxia","pixiv","70858371"],[7,"2020-06-06","https://www.pixiv.net/users/135302","ゆきさめ","https://pan.baidu.com/s/1zfkHLvYkJ62MKOFh0nrazQ","552a","pixiv","73442715"],[8,"2020-06-27","https://www.pixiv.net/users/35081","Tiv","https://pan.baidu.com/s/1t2rPT6JTXr2IggA6nHG_Xg","6u21","pixiv","76122099-1"]
	,[9,"2020-06-27","https://www.pixiv.net/users/212801","Anmi","https://pan.baidu.com/s/1soMY2kTo_-xLHfjIJmit0Q","o5e0","pixiv","69446164"],[10,"2020-06-27","https://www.pixiv.net/users/5229572","まふゆ","https://pan.baidu.com/s/1ZJMrR3p3w-72xMZDYURYHw","iqyc","pixiv","78346150"],[11,"2020-06-27","https://www.pixiv.net/users/4816744","みわべさくら","https://pan.baidu.com/s/1aqQggxHiz5dRCXQzOFJUNw","57ac","pixiv","71902324"]
	,[12,"2020-06-27","https://www.pixiv.net/users/3302692","遠坂あさぎ","https://pan.baidu.com/s/1u5KD3z3ViA1Wf9uLfS54VQ","cxwd","pixiv","75902731"],[13,"2020-03-31","https://www.pixiv.net/users/13992671","木なこ","https://pan.baidu.com/s/11PRdmoAmIDVRxPMOo20GlQ","teu9","pixiv","77793715"],[14,"2020-06-27","https://www.pixiv.net/users/502358","和錆","https://pan.baidu.com/s/1Dneyg_oDoNKn-Rs6MKiKxQ","8nii","pixiv","81854218"]
	,[15,"2020-06-27","https://www.pixiv.net/users/264932","TwinBox","https://pan.baidu.com/s/1xonvJiiKI5ZAXQUn5cfEJA","p6jb","pixiv","78315982"],[16,"2020-06-28","https://www.pixiv.net/users/23945843","Sul","https://pan.baidu.com/s/1POOwg1o7KMcgLT5YdXBV8A","6vrm","pixiv","76559068"],[17,"2020-06-28","https://www.pixiv.net/users/24218478","￦ANKE","https://pan.baidu.com/s/1vi8nQJf3PSM8L9An_1g24w","zcaw","pixiv","75726416"]
	,[18,"2020-06-28","https://www.pixiv.net/users/3424578","こーやふ","https://pan.baidu.com/s/19lGuJ7K93lGNGBnmwYkoWg","o4st","pixiv","78332144"],[19,"2020-06-28","https://www.pixiv.net/users/5323203","TAYA","https://pan.baidu.com/s/1U2xSIfTSiUOCM0bzZO8Gxg","r3fp","pixiv","77202647"],[20,"2020-06-28","https://www.pixiv.net/users/4462245","幻像黒兎","https://pan.baidu.com/s/1gYzKFxCY1GUbWp_R3Xg7fg","5ew6","pixiv","76043793"]
	,[21,"2020-06-28","https://www.pixiv.net/users/2188232","wlop","https://pan.baidu.com/s/182ehD3q7BIOhfhzDSwR90A","kqcg","pixiv","79784330"],[22,"2020-06-28","https://www.pixiv.net/users/2088434","トマリ","https://pan.baidu.com/s/1LyVmL3-GJGuVfABSqFFeDw","i2sb","pixiv","76394208"],[23,"2020-06-28","https://www.pixiv.net/users/11246082","Miv4t","https://pan.baidu.com/s/1IzJqJg-R4gfuWiu4diqufw","k7y5","pixiv","77186528-1"]
	,[24,"2020-07-03","https://www.pixiv.net/users/58338","フカヒレ","https://pan.baidu.com/s/12h5ZtQMyVoVpjasKN7unEg","s7x0","pixiv","82712750"],[25,"2020-07-03","https://www.pixiv.net/users/1113943","ももこ","https://pan.baidu.com/s/1a83ONV679_oPQkMX0bEDrQ","75as","pixiv","73718366"],[26,"2020-07-03","https://www.pixiv.net/users/3367474","Atha（アサ）","https://pan.baidu.com/s/1KnIYqGr46cFBZJrdv6369A","316q","pixiv","81903023"]
	,[27,"2020-07-04","https://www.pixiv.net/users/1198913","ほし","https://pan.baidu.com/s/1k6ngqnqLXllL7H_JZerITg","5y32","pixiv","78106859"],[28,"2020-07-04","https://www.pixiv.net/users/1710950","純粋","https://pan.baidu.com/s/15XX9OdUya47yiGTtCVopsg","ctsp","pixiv","68295064"],[29,"2020-07-04","https://www.pixiv.net/users/25344318","日下コウ","https://pan.baidu.com/s/1p4VDS0JeOjqxBryCFyo2Ww","thra","pixiv","71446753"]
	,[30,"2020-07-08","https://www.pixiv.net/users/2106422","はねこと","https://pan.baidu.com/s/1cpyRkVk1oM9NSNz77mKHyg","al2g","pixiv","82276846-1"],[31,"2020-07-14","https://www.pixiv.net/users/772547","loundraw","https://pan.baidu.com/s/1VJzcwrIfP-viOFCroxxZEg","lips","pixiv","69460730"],[32,"2020-07-14","https://www.pixiv.net/users/18403608","マシマサキ","https://pan.baidu.com/s/1gWFAgNSSWCAkTrc-ltCQ9A","aedu","pixiv","78332312"]
	,[33,"2020-07-14","https://www.pixiv.net/users/2131660","荻pote","https://pan.baidu.com/s/1ri8vS4FSFIxWVZD4dGj3Qg","8m38","pixiv","77734148"],[34,"2020-07-19","https://www.pixiv.net/users/27517","藤原","https://pan.baidu.com/s/1NNI5DNCc7xI39lyQR97tbA","lips","pixiv","77959944"],[35,"2020-07-26","https://www.pixiv.net/users/189732","爽々","https://pan.baidu.com/s/1YwV7-pfxLvGMj-9VFhCXgw","lips","pixiv","77619497",1]
	,[36,"2020-07-26","https://www.pixiv.net/users/1023317","gomzi","https://pan.baidu.com/s/1Yg_6dc_U-JREdOld4FNoPA","lips","pixiv","78315945",1],[37,"2020-07-26","https://www.pixiv.net/users/3428351","カット","https://pan.baidu.com/s/1gJBH5sY1AXjQaWA8Nxdx8g","lips","pixiv","72346170",1],[38,"2020-07-29","https://www.pixiv.net/users/25760573","rurudo","https://pan.baidu.com/s/1wLC91wTUQLpHis5_LBWEcQ","lips","pixiv","72114086",1]
	,[39,"2020-07-28","https://www.pixiv.net/users/10292","ふーみ","https://pan.baidu.com/s/1RV6eeSoSga5EvAZwCEDojg","lips","pixiv",[76372256,78377223],2],[40,"2020-07-28","https://www.pixiv.net/users/2827978","nineo","https://pan.baidu.com/s/1ahaLtLHEzJ2vcIRvejbaGw","lips","pixiv","67329057",1],[41,"2020-08-02","https://www.pixiv.net/users/73152","光崎","https://pan.baidu.com/s/1U9uikRoVcvk2A4RX3x30gQ","lips","pixiv",[83380862,82432085],2]
	,[42,"2020-08-02","https://www.pixiv.net/users/9427","アマガイタロー","https://pan.baidu.com/s/1Bgi-tbCNUgfsyZE8y_Dg2Q","lips","pixiv","78446413",1],[43,"2020-08-02","https://www.pixiv.net/users/795196","DSマイル","https://pan.baidu.com/s/1rhA7ASa-IMDkho-mWxSt1g","lips","pixiv",[76626151,80315592],2],[44,"2020-08-02","https://www.pixiv.net/users/27578718","saki","https://pan.baidu.com/s/1-qqtcKPtCI1yhTF0IOFGPg","lips","pixiv","83381066",1]
	,[45,"2020-08-03","https://www.pixiv.net/users/1554775","米山舞","https://pan.baidu.com/s/1Mk2D0aYQRn1yeWl5vBjpZA","lips","pixiv","74182068",1],[46,"2020-08-03","https://www.pixiv.net/users/6662895","ATDAN-","https://pan.baidu.com/s/1LXsNiAYfusRXA2bGP2xWqA","lips","pixiv","77962283",1],[47,"2020-08-03","https://www.pixiv.net/users/122500","月岡","https://pan.baidu.com/s/1PAPbIL-oPhRAjdy7H3qLDg","lips","pixiv","56521957",1]
	,[48,"2020-08-03","https://www.pixiv.net/users/7324626","美和野らぐ","https://pan.baidu.com/s/1ogxzNy2MmW6FrZnweFrh-A","lips","pixiv",[80775090,81163577],2],[49,"2020-08-03","https://www.pixiv.net/users/444732","れい亜","https://pan.baidu.com/s/1PLGKi2fTp9danH6CiIJ67w","lips","pixiv",[82234547,79324063],2],[50,"2020-08-03","https://www.pixiv.net/users/145944","にもし","https://pan.baidu.com/s/1owI5NVgdtHX1RVU9h9VKCg","lips","pixiv","78500555",1]
	,[51,"2020-08-08","https://www.pixiv.net/users/22055974","Kinty","https://pan.baidu.com/s/1luXjcMycnw1i7dExY0OO9w","lips","pixiv",[80657110,83471888],2],[52,"2020-08-08","https://www.pixiv.net/users/12929463","ポプル","https://pan.baidu.com/s/1wYV8fLMHixVhcknAxPberw","lips","pixiv","72551335",1],[53,"2020-08-08","https://www.pixiv.net/users/4268983","わみず","https://pan.baidu.com/s/1Bo_G-phrGhRk6c79nkGi2Q","lips","pixiv","83302258",1]
	,[54,"2020-08-08","https://www.pixiv.net/users/7631951","白祈","https://pan.baidu.com/s/1gWuvK-han8b_sdRX_2zvZw","lips","pixiv","83406798",1],[55,"2020-08-08","https://www.pixiv.net/users/21995968","DeeCHA","https://pan.baidu.com/s/1iDwRsCYRYozwSE4MN28efQ","lips","pixiv","74224289",1],[56,"2020-08-08","https://www.pixiv.net/users/7121031","Mi'yuki","https://pan.baidu.com/s/1qnlafP3eTxYVsEcL_CdjrQ","lips","pixiv","73923118",1]
	,[57,"2020-08-08","https://www.pixiv.net/users/1723558","tokki","https://pan.baidu.com/s/15x9qURa1Ypi2Fp83Lj7yTg","lips","pixiv","80877575",1],[58,"2020-08-08","https://www.pixiv.net/users/5130774","SWAV","https://pan.baidu.com/s/1KSmxNs62jDcoO132EbNdmA","lips","pixiv",[82542759,83018637],2],[59,"2020-08-08","https://www.pixiv.net/users/11376797","Recneps-SAIS","https://pan.baidu.com/s/1O20yA7lq8DcU6HcIv4YfZw","lips","pixiv","79989718",1]
	,[60,"2020-08-08","https://www.pixiv.net/users/9212166","ふわり","https://pan.baidu.com/s/1w1SI9gEfnuwXuv1ri-hiog","lips","pixiv",[77413464,82782759],2]],
			data1: [[0,"2020-07-20","https://pan.baidu.com/s/1T-Nk1jGACupooQuuwzNaww","(C97) [HitenKei (Hiten)] PETITE AMIE (オリジナル)",1,"lips","C97"],[1,"2020-07-20","https://pan.baidu.com/s/1goKjL053v2UHzCvurO87Sg","(C97) [flourish (アシマ)] DO AS I DO (オリジナル)",2,"lips","C97"],[2,"2020-07-20","https://pan.baidu.com/s/1lec8kyyM_FJkfC3aGRRbbQ","(C97) [hanasaku (Aちき)] Re (オリジナル)",2,"lips","C97"],[3,"2020-07-20","https://pan.baidu.com/s/1i7bw159BNUhNdgbdh97JHA","(C97) [メガネ少女 (Anmi)] Avian Romance Pink label7 (オリジナル)",1,"lips","C97"]
,[4,"2020-07-20","https://pan.baidu.com/s/1GaCfePToUCrSyA2pITv3zg","(C98) [AliceSyndrome＊ (遠坂あさぎ)] Various notes 02 (よろず)",2,"lips","C98"],[5,"2020-07-20","https://pan.baidu.com/s/19hAF9CnFdjASJBJ7evNv4Q","(C97) [amasyrup (甘城なつき)] amasyrup (オリジナル)",2,"lips","C97"],[6,"2020-07-20","https://pan.baidu.com/s/1sYpE5_KNR1saJHx4noXX2w","(C97) [Pion (みわべさくら)] シタギプラス 2 (オリジナル)",1,"lips","C97"],[7,"2020-08-08","https://pan.baidu.com/s/1fV0KCobpJ57Q6z479hwwXg","(C96) [atelier Tiv artworks (Tiv)] あめおとめがたり (オリジナル)",1,"lips","C96"]
,[8,"2020-08-08","https://pan.baidu.com/s/18ij8VR81RR7dW6hu6UclUA","(C98) [ETERNAL LAND (6U☆)] FAVO! WORKS 4 (五等分の花嫁)",2,"lips","C98"],[9,"2020-08-08","https://pan.baidu.com/s/11idLCjUtc8OVQQ0ZA25jlQ","(C96) [hanasaku (Aちき)] THE PALE (オリジナル)",2,"lips","C96"],[10,"2020-08-08","https://pan.baidu.com/s/1ucQs9EG4LGAnDUEUR6IjXA","(C96) [RUINON (フカヒレ)] AQUASCAPE (オリジナル)",1,"lips","C96"],[11,"2020-08-08","https://pan.baidu.com/s/10f8XSbndOWlXk9rkxx_P8w","(C96) [rurudot (rurudo)] LOVE CALL (オリジナル)",2,"lips","C96"]
,[12,"2020-08-08","https://pan.baidu.com/s/15Frgu1r0kGXd4sDdETXMzw","(C98) [CANVAS (森倉円)] Lingerie Bouquet (オリジナル)",1,"lips","C98"],[13,"2020-08-08","https://pan.baidu.com/s/1KHiuPpgJvgpyEHw8CEf4dQ","(C98) [HitenKei (Hiten)] Ephemeral Color (オリジナル)",2,"lips","C98"],[14,"2020-08-08","https://pan.baidu.com/s/1bUrCJLAyuzorRvWoehDeyw","(C97) [Lunaberry (nana)] Falling Roses (オリジナル)",2,"lips","C97"],[15,"2020-08-08","https://pan.baidu.com/s/1NaabcDasNCWNhi36L-NXOw","(C97) [いもむすめ。 (荻pote)] HEARTS (オリジナル)",1,"lips","C97"]
,[16,"2020-08-08","https://pan.baidu.com/s/1iFcoiAUEXfNOOIr-_JUN8Q","(C97) [salmon (まふゆ)] LOVE ME (オリジナル)",2,"lips","C97"],[17,"2020-08-08","https://pan.baidu.com/s/1GQJk0uNLrtHelpgjlaw1iw","(C97) [Tsundere is Love (DSマイル)] Ribbon (オリジナル)",1,"lips","C97"],[18,"2020-08-08","https://pan.baidu.com/s/18RDCIh21ZUyWO3-EcwBwyw","(C97) [きなこもっち (しゅがお)] BEAST (オリジナル)",2,"lips","C97"],[19,"2020-08-08","https://pan.baidu.com/s/1vnrB0xkpOcFO2ORTsBvDLQ","(C97) [5年目の放課後 (カントク)] 濡れ (オリジナル)",1,"lips","C97"]
,[20,"2020-08-08","https://pan.baidu.com/s/1O9xtM41C6u0OZeyv2ya50w","(C96) [art en ciel. (にじはしそら)] GOOD OLD-FASHIONED LOVER GIRL #3 (よろず)",2,"lips","C96"],[21,"2020-08-08","https://pan.baidu.com/s/1LQb8-mC8T_V0iWCIj_VZvg","(C96) [G.H.K (光崎)] FGO Fan Art Collection vol.3 (Fate Grand Order)",2,"lips","C96"],[22,"2020-08-08","https://pan.baidu.com/s/1ngixOdjoVPxGEbNJ1aKXaA","(C96) [アニマルハーブ (夜ノみつき)] PYTHONISSAM CARCEREM (Fate Grand Order)",2,"lips","C96"],[23,"2020-08-08","https://pan.baidu.com/s/1Xoe_ki3y7XMbAZGYqCoKTQ","(C98) [しらたまこ (しらたま)] Étoile 09 (よろず)",1,"lips","C98"]
,[24,"2020-08-08","https://pan.baidu.com/s/1LFp-OOEKLHjt4YGgYHKfug","(C98) [Chilly polka (すいみゃ)] ツインテラリウム (オリジナル)",1,"lips","C98"],[25,"2020-08-08","https://pan.baidu.com/s/1Z8LU7aXCEdOvNWWxZPOlYw","(C95) [CANVAS+GARDEN (Miyasaka Miyu, Miyasaka Naco)] Bouquet25",2,"lips","C95"],[26,"2020-08-08","https://pan.baidu.com/s/1z2bW3WTqbU4Hkgfw4wvzbg","(C97) [PSYCHOSTEAK (2)] KARMA ASSORT 0.5 (オリジナル)",1,"lips","C97"],[27,"2020-08-08","https://pan.baidu.com/s/1QHAMYvcnr_Fdx8kzf_ZbxQ","(C97) [KOHARUCHAYA (きみしま青)] Caramel milk tea (オリジナル)",1,"lips","C97"]
,[28,"2020-08-08","https://pan.baidu.com/s/1sILh6VFSebr3TU61nMpWGQ","(C97) [Floralia (gomzi)] Peaceful Harmony (オリジナル)",1,"lips","C97"],[29,"2020-08-08","https://pan.baidu.com/s/1eAkO1gw_vjuPO5ZFy2Fxpw","(C97) [butterfly (なび)] JK。 (オリジナル)",2,"lips","C97"],[30,"2020-08-08","https://pan.baidu.com/s/1nkA6zei5jputA0SlVJAUPA","(C96) [flourish (アシマ)] OIL AND WATER (オリジナル)",1,"lips","C96"]],
			pack: {
				artBook : false,
				packTime: '',
				count: 1,
				cover: '',
				source: '',
				illustrator: '',
				authorLink: '',
				duPanLink: '',
				duCode: '',
				bookTitle: '',
				bookTag: '',
				nsfw: '',
				uid: '',
				uploadUser: ''
			},
			cover1: '',
			cover2: '',
			userInfo: '',
		}
	},
	methods: {
		getUser() {
			this.userInfo = this.$store.getters.getUser
		},
		submit(num) {
			let _this = this
			this.axios.post('savePixiv', _this.pack).then(function(res){
				if(res.data){
					_this.$notify({
						title: '成功',
						message: '成功插入：'+num,
						type: 'success'
					});	
				}
			},function(err){
				console.log(err);
			})
		},
		save(num){
			this.pack.packTime = this.data[num-1][1]
			this.pack.illustrator = this.data[num-1][3]
			this.pack.authorLink = this.data[num-1][2]
			this.pack.source = this.data[num-1][6]
			this.pack.duPanLink = this.data[num-1][4]
			this.pack.duCode = this.data[num-1][5]
			this.pack.uid = this.userInfo.uid
			this.pack.uploadUser = this.userInfo.username
			this.pack.nsfw = false
			this.pack.artBook = false
			if(num>=35){
				this.pack.count = this.data[num-1][8]
				this.data[num-1][8] == 2?this.pack.cover = this.data[num-1][7][0]+','+this.data[num-1][7][1]:this.pack.cover=this.data[num-1][7]
				
			}else{
				this.pack.cover = this.data[num-1][7]
			}
			this.submit(num)
		},
		saveArt(num){
			this.pack.packTime = this.data1[num-1][1]
			this.pack.duPanLink = this.data1[num-1][2]
			this.pack.duCode= this.data1[num-1][5]
			this.pack.count = this.data1[num-1][4]
			if(this.data1[num-1][4]==2){
				this.pack.cover = this.cover1+','+this.cover2
			}else{
				this.pack.cover = this.cover1
			}
			this.pack.nsfw = false
			this.pack.artBook = true
			this.pack.source = "online"
			this.pack.bookTitle = this.data1[num-1][3]
			this.pack.bookTag = this.data1[num-1][6]
			this.pack.uid = this.userInfo.uid
			this.pack.uploadUser = this.userInfo.username
			this.submit(num)
		}
	},
	created() {
		this.getUser()
	},
	mounted() {
		document.body.style.overflow='hidden'
		setTimeout(() => {
			this.$store.commit('showLoading')
			document.body.style.overflow=''
			console.log(this.data1);
		},2000)
	},
	destroyed() {
		this.$store.commit('loading')
	}
}
</script>

<style>
.test{
	width: 80%;
	margin: 2em auto;
}
.test .el-button.is-round{
	margin: .5em;
}
.test-1{
	margin-bottom: 2em;
}
</style>
